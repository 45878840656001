import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/mdx-layout.js";
import { Link } from "gatsby";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Page Not Found`}</h1>
    <p>{`Sorry 😔 we could not find what you were looking for.`}</p>
    <p>{`Try creating a page in `}<code parentName="p" {...{
        "className": "text"
      }}>{`src/pages/.`}</code></p>
    <Link href="/" mdxType="Link">Go home</Link>
    <br />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      